exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancellation-js": () => import("./../../../src/pages/cancellation.js" /* webpackChunkName: "component---src-pages-cancellation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-simply-sudoku-js": () => import("./../../../src/pages/privacy-policy-simply-sudoku.js" /* webpackChunkName: "component---src-pages-privacy-policy-simply-sudoku-js" */),
  "component---src-pages-projects-random-quote-js": () => import("./../../../src/pages/projects/random-quote.js" /* webpackChunkName: "component---src-pages-projects-random-quote-js" */),
  "component---src-pages-projects-sudoku-js": () => import("./../../../src/pages/projects/sudoku.js" /* webpackChunkName: "component---src-pages-projects-sudoku-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-consulting-js": () => import("./../../../src/templates/consulting.js" /* webpackChunkName: "component---src-templates-consulting-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-development-js": () => import("./../../../src/templates/development.js" /* webpackChunkName: "component---src-templates-development-js" */),
  "component---src-templates-miscellaneous-js": () => import("./../../../src/templates/miscellaneous.js" /* webpackChunkName: "component---src-templates-miscellaneous-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-tutoring-js": () => import("./../../../src/templates/tutoring.js" /* webpackChunkName: "component---src-templates-tutoring-js" */)
}

